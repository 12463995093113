import { render, staticRenderFns } from "./Bulletin.vue?vue&type=template&id=241a306c&scoped=true&"
import script from "./Bulletin.vue?vue&type=script&lang=js&"
export * from "./Bulletin.vue?vue&type=script&lang=js&"
import style0 from "./Bulletin.vue?vue&type=style&index=0&id=241a306c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241a306c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VAlert,VCard,VIcon,VScrollYTransition})
