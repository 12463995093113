import { render, staticRenderFns } from "./ServerNotifyOverlay.vue?vue&type=template&id=4875a823&scoped=true&"
import script from "./ServerNotifyOverlay.vue?vue&type=script&lang=js&"
export * from "./ServerNotifyOverlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4875a823",
  null
  
)

/* custom blocks */
import block0 from "./ServerNotifyOverlay.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VOverlay,VRow})
