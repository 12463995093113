import { render, staticRenderFns } from "./Stage.vue?vue&type=template&id=21073b69&scoped=true&"
import script from "./Stage.vue?vue&type=script&lang=js&"
export * from "./Stage.vue?vue&type=script&lang=js&"
import style0 from "./Stage.vue?vue&type=style&index=0&id=21073b69&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21073b69",
  null
  
)

/* custom blocks */
import block0 from "./Stage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCardTitle,VRow,VSpacer})
